import React from 'react';
import GatsbyLink from '../../GatsbyLink';
import './PostListNavigation.scss'

export const PostListNavigation = (props) => {
    const { pathPrefix, categoryFilter, categoryFilterLinks, postType = 'post', updateActiveCategory, activeCat } = props;

    const { nodes: posts } = categoryFilter

    let categoriesArray = []
    posts && posts.map(post =>
      post.categories && post.categories.map( category => {
        let thisCompare = categoriesArray.find(compare => compare.wordpress_id === category.wordpress_id)
        !thisCompare && category.slug !== 'uncategorized' && categoriesArray.push(category)
      })
    )

    return (
      <div className="post-list-navigation">
        <div className="inner">
          <span>FILTER BY:</span>
          {postType === 'post' ? (
            <ul>
              <li><GatsbyLink to={`/${pathPrefix}/`} activeClassName="active">ALL</GatsbyLink></li>
              {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorised' && (
                <li key={index}><GatsbyLink to={`/${pathPrefix}/category/${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }}/></li>
              ))}
            </ul>
          ) : categoryFilterLinks === 'false' ? (
            // navigation with filter buttons on gallery landing page
            <ul>
              <li><button type="button" aria-label="Active Category" className={activeCat === null ? 'active' : ''} onClick={() => updateActiveCategory(null)}>ALL</button></li>
              {categoriesArray && categoriesArray.map((category , index) => (
                <li key={index}><button aria-label="Category" className={activeCat === category.slug ? 'active' : ''} type="button" onClick={() => updateActiveCategory(category.slug)}
                dangerouslySetInnerHTML={{ __html: category.name }} /> </li>
              ))}
            </ul>
          ) : (
            // navigation with links on gallery category page
            <ul>
              {/* <li><button type="button" className={activeCat === null ? 'active' : ''} onClick={() => updateActiveCategory(null)}>ALL</button></li> */}
              {categoriesArray && categoriesArray.map((category , index) => category.slug !== 'uncategorised' && (
                // add link to other categories
                <li key={index}><GatsbyLink to={`/${pathPrefix}/category/${category.slug}/`} activeClassName="active" dangerouslySetInnerHTML={{ __html: category.name }}/></li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )
}
