import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby';
import './ProductList.scss';
import RenderContent from '../../RenderContent';
import Pagination from '../Pagination';
import { Image } from '../../Image';
import { FaChevronRight } from 'react-icons/fa';
import ProductListItem from '../ProductListItem'
import { PostListNavigation } from '../PostListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categoryFilter, categories, pathPrefix, siteMetadata} = this.props
    return (
      <section className="product-list">
        <PostListNavigation pathPrefix={pathPrefix} categoryFilter={categoryFilter}/>
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map((slide, inedx) => {
                  return (
                    <ProductListItem key={inedx} data={slide.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} />
                  )
                })}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={`/${pathPrefix}/`} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ProductListFields on wordpress__wp_products {
    id
    wordpress_id
    categories {
      id
      name
      slug
    }
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    link
    acf {
      productPrice
      productDetails
    }
    featured_image_url {
      source_url
    }
  }
`
