import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './Pagination.scss';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const { previousPagePath = null, nextPagePath = null, numberOfPages = null } = pageContext
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
        pages.push(i+1);
  }
  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        <div className="nav">
          {pages.map((key, index) => (
            <GatsbyLink key={index} to={key !== 1 ? `${pathPrefix}page/${key}/` : pathPrefix } activeClassName="active">{key}</GatsbyLink>
          ))}
        </div>
        <div className="actions">
          {previousPagePath && (
            <GatsbyLink to={`${previousPagePath}/`} className="prev" />
          )}
          {nextPagePath && (
            <GatsbyLink to={`${nextPagePath}/`} className="next" />
          )}
        </div>
      </div>
    </nav>
  )
}

export default Pagination
